import { createFeatureSelector, createSelector } from '@ngrx/store';
import { wishlistFeatureKey } from './wishlist.reducer';
import { AppState } from '../app.store';

const getWishlist = createFeatureSelector<AppState>(wishlistFeatureKey);

export const getWishlistData = createSelector(
    getWishlist,
    (state: any) => {
        return state.data;
    }
)

export const getWishlistCount = createSelector(
    getWishlist,
    (state: any) => {
        return state.data?.length;
    }
)

export const getWishlistStatus = createSelector(
    getWishlist,
    (state: any) => {
        return state.status
    }
)