import { createReducer, on } from '@ngrx/store';
import { WishlistActions } from './wishlist.actions';

export const wishlistFeatureKey = 'wishlist';

export interface State {
  data: any,
  status: string,

}

export const initialState: State = {
  data: null,
  status: 'pending'
};

export const reducer = createReducer(
  initialState,
  on(
    WishlistActions.loadWishlistSuccess,
    (state, action) => {
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    }
  ),
  on(
    WishlistActions.loadWishlist,
    (state, action) => {
      return {
        ...state,
        status: 'loading',
      }
    }
  ),
  on(
    WishlistActions.removeWishlistData,
    (state, action) => {
      return {
        ...state,
        data: null
      }
    }
  )
);

